/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax"
;

.no-cover .page-title {
	color: #3b1c4a;
}

a {
	color: #3b1c4a;
}

.post-meta {
	margin-bottom: 1.75rem;
}


@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-bold-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-italic-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-light-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-regular-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans_bold_macroman/OpenSans-Bold-webfont.eot');
    src: url('../fonts/opensans_bold_macroman/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans_bold_macroman/OpenSans-Bold-webfont.woff') format('woff'),
         url('../fonts/opensans_bold_macroman/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans_bold_macroman/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.eot');
    src: url('../fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('../fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: 700;
    font-style: italic;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans_italic_macroman/OpenSans-Italic-webfont.eot');
    src: url('../fonts/opensans_italic_macroman/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans_italic_macroman/OpenSans-Italic-webfont.woff') format('woff'),
         url('../fonts/opensans_italic_macroman/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('../fonts/opensans_italic_macroman/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans_regular_macroman/OpenSans-Regular-webfont.eot');
    src: url('../fonts/opensans_regular_macroman/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans_regular_macroman/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/opensans_regular_macroman/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans_regular_macroman/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;

}
